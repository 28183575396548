import _objectSpread from "/Users/teerapatcheung/Desktop/0-Project/logistics/logistics_metronic_vue/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.string.search.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.find.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import debounce from "debounce";
import TopupList from "./components/TopupList";
import TransactionList from "./components/TransactionList";
import WithdrawList from "./components/WithdrawList";
import { imageUrlApi, WEBSITE } from "@/config";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";
export default {
  name: "WalletList",
  mounted: function mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{
      title: "กระเป๋าเงิน"
    }]);
    this.permissionTopup = this.checkPermission("admin_topup", 1);
    this.permissionBalance = this.checkPermission("admin_balance", 1);

    if (!this.permissionBalance) {
      this.cat = 2;
    }
  },
  created: function created() {
    document.title = "\u0E01\u0E23\u0E30\u0E40\u0E1B\u0E4B\u0E32\u0E40\u0E07\u0E34\u0E19 | ".concat(WEBSITE);
  },
  computed: _objectSpread({}, mapGetters(["currentPermission", "getCurrentNotiAdmin"])),
  components: {
    TopupList: TopupList,
    TransactionList: TransactionList,
    WithdrawList: WithdrawList
  },
  data: function data() {
    var _this$$route$query$ca = this.$route.query.cat,
        cat = _this$$route$query$ca === void 0 ? 1 : _this$$route$query$ca;
    return {
      page: 1,
      pageSize: 10,
      loading: false,
      status: "",
      search: "",
      cat: Number(cat),
      permissionTopup: false,
      permissionBalance: false
    };
  },
  methods: {
    imageUrlApi: imageUrlApi,
    debounceInput: debounce(function (e) {
      this.search = e.target.value;
      this.page = 1;
    }, 400),
    pushCat: function pushCat(val) {
      var newpath = "".concat(this.$route.path, "?cat=").concat(val);

      if (this.$route.fullPath !== newpath) {
        this.$router.push({
          name: "admin_wallet",
          query: {
            cat: val
          }
        });
      }
    },
    checkPermission: function checkPermission(name, type) {
      if (!this.currentPermission) {
        return false;
      }

      var permissionRole = this.currentPermission.permissionRole;
      var find = permissionRole.find(function (x) {
        return x.permissionInfo && x.permissionInfo.permissionName === name;
      });

      if (!find) {
        return false;
      }

      if (type === 0 && find.readableRole) {
        return true;
      }

      if (type === 1 && find.writeableRole) {
        return true;
      }

      return false;
    }
  },
  watch: {
    "$route.query.cat": function $routeQueryCat(id) {
      this.cat = Number(id) || 1;
    }
  }
};