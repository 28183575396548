import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.string.search.js";
import "core-js/modules/es.regexp.exec.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import bigDecimal from "js-big-decimal";
import dayjs from "dayjs";
import { imageUrlApi, WEBSITE } from "@/config";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GET_TRANSACTIONS } from "@/graphql/admin/transaction.js";
export default {
  name: "TransactionList",
  props: ["search"],
  mounted: function mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{
      title: "กระเป๋าเงิน"
    }]);
  },
  created: function created() {
    document.title = "\u0E01\u0E23\u0E30\u0E40\u0E1B\u0E4B\u0E32\u0E40\u0E07\u0E34\u0E19 | ".concat(WEBSITE);
  },
  data: function data() {
    var _this$$route$query$pa = this.$route.query.page,
        page = _this$$route$query$pa === void 0 ? 1 : _this$$route$query$pa;
    return {
      page: Number(page) || 1,
      pageSize: 10,
      loading: false,
      status: "",
      cat: 1
    };
  },
  methods: {
    imageUrlApi: imageUrlApi,
    getStatusColor: function getStatusColor(status) {
      switch (status) {
        case -10:
          return "default";

        case 0:
          return "warning";

        case 1:
          return "success";

        case 2:
          return "warning";

        case 3:
          return "success";

        default:
          return "primary";
      }
    },
    format_datetime: function format_datetime(value) {
      if (value) {
        return dayjs(String(value)).format("DD/MM/YYYY HH:mm");
      }

      return "-";
    },
    format_number: function format_number(val) {
      var decimal = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
      var format = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

      if (format) {
        if (val >= 0) return "\u0E3F".concat(Number(bigDecimal.round(val, decimal)).toLocaleString(undefined, {
          minimumFractionDigits: decimal,
          maximumFractionDigits: decimal
        }));
        return "-\u0E3F".concat(Number(bigDecimal.round(Math.abs(val), decimal)).toLocaleString(undefined, {
          minimumFractionDigits: decimal,
          maximumFractionDigits: decimal
        }));
      }

      return bigDecimal.round(val, decimal);
    }
  },
  watch: {
    page: function page(val) {
      var newpath = "".concat(this.$route.path, "?cat=").concat(this.cat, "&page=").concat(val);

      if (newpath !== this.$route.fullPath) {
        this.$router.push(newpath);
      }
    },
    "$route.query.page": function $routeQueryPage(val) {
      this.page = Number(val) || 1;
    }
  },
  apollo: {
    systemTransactions: {
      query: GET_TRANSACTIONS,
      variables: function variables() {
        return {
          pageSize: this.pageSize,
          page: this.page,
          search: this.search
        };
      }
    }
  }
};